import { eventNameConstants, trackEvent } from 'dibs-tracking';

export const trackNullResultsDisplayed = (): void => {
    trackEvent({
        category: 'null results module interaction',
        action: 'null modules displayed',
        label: 'none',
        value: 0,
    });
};

export const trackNullResultsFollowSearch = (): void => {
    const category = 'search favoriting';
    const action = 'search added';
    trackEvent({
        category,
        eventName: eventNameConstants.EVENT_WISHLIST,
        action,
        interaction_type: category,
        label: 'null results',
        step_interaction_name: action,
        value: 0,
    });
};

export const trackL1ModuleClick = (l1Name: string): void => {
    trackEvent({
        category: 'null results module interaction',
        action: 'l1 link clicked',
        label: l1Name.toLowerCase(),
        value: 0,
    });
};

export const trackL2ModuleClick = (l1Name: string, primary: boolean): void => {
    const description = primary ? 'primary' : 'secondary';
    trackEvent({
        category: 'null results module interaction',
        action: 'l2 link clicked',
        label: `${l1Name.toLowerCase()} | ${description} l2`,
        value: 0,
    });
};

export const trackCreatorModuleClick = (creatorName: string): void => {
    trackEvent({
        category: 'null results module interaction',
        action: 'creator link click',
        label: creatorName,
        value: 0,
    });
};

export const trackRoomModuleClick = (roomName: string): void => {
    trackEvent({
        category: 'null results module interaction',
        action: 'room link click',
        label: roomName.toLowerCase(),
        value: 0,
    });
};

export const trackModuleClick = (actionLabel: string, index: number): void => {
    trackEvent({
        category: 'null results module interaction',
        action: `${actionLabel} clicked`,
        label: index + 1, // Tracking needs non zero index position.
        value: 0,
        noninteraction: 0,
    });
};
