/**
 * @generated SignedSource<<afac9a18918c26e47ada13540dbc2e53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedTrackingContainer_viewer$data = {
  readonly isEligibleForRerank?: boolean | null;
  readonly " $fragmentType": "SbSharedTrackingContainer_viewer";
};
export type SbSharedTrackingContainer_viewer$key = {
  readonly " $data"?: SbSharedTrackingContainer_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedTrackingContainer_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isClient"
    },
    {
      "kind": "RootArgument",
      "name": "rerankGuestId"
    },
    {
      "kind": "RootArgument",
      "name": "rerankUserId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedTrackingContainer_viewer",
  "selections": [
    {
      "condition": "isClient",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "guestId",
              "variableName": "rerankGuestId"
            },
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "rerankUserId"
            }
          ],
          "kind": "ScalarField",
          "name": "isEligibleForRerank",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "27efcdeef85bf0d2037e8d4ac4bd51d5";

export default node;
