import { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';
import { getPageviewData } from '../../utils/tracking/searchBrowse/getPageviewData';
import { getPublisherTagInstance } from '../../utils/getPublisherTagInstance';
import { trackSortEvent } from '../../utils/tracking/searchBrowse/trackSortEvent';
import { addPersonalizationTrackingData } from '../../utils/tracking/searchBrowse/addPersonalizationTrackingData';
import { trackNullResultsDisplayed } from '../../utils/tracking/searchBrowse/nullResultsTracking';
import { SharedTrackingContainer } from '../../shared/SharedTrackingContainer';
import { pageTypeConstants } from '../../constants/pageTypeConstants';
import { SEARCH_TYPES, FILTER_SEARCH_TYPE, SortMap } from '../../constants/sbConstants';
import * as tracking from 'dibs-tracking';
import { getFilterValueByUrlLabel } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { ServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';

import {
    bestSellersSellabilityTracking,
    personalizedRerankTracking,
    userEngagementBoostTracking,
    itemPriceInSellabilityTracking,
    trackActiveAbTests,
} from '../SbSharedTrackingFunctions/SbSharedTrackingFunctions';
import { trackSellerBrandingRemovalAbTestVariant } from 'dibs-buyer-layout/exports/sellerBrandingRemovalAbTestHelpers';
import {
    checkRewardedSeller,
    checkTradeUserUnderTopLevelLoyaltyTiers,
} from 'dibs-buyer-layout/exports/sellerBrandingHelpers';
import { updateSaveSearchMutation } from '../../mutations/updateSaveSearchMutation';
import {
    isUserEngagementBoostAbTestVariantOrControl,
    isUserEngagementBoostAbTestControl,
} from '../../utils/abTestHelper';

const { AUCTION, SEARCH, DEALER, MORE_FROM_SELLER, BUY } = pageTypeConstants;
const { FUZZY } = SEARCH_TYPES;

class SbSharedTrackingContainerComponent extends Component {
    constructor() {
        super();

        this.handleTrackingFired = this.handleTrackingFired.bind(this);

        this.state = {
            visitCount: 0,
        };

        this.hasDisplayUriRefChanged = false;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.hasDisplayUriRefChanged = this.hasPropChanged(nextProps, [
            'itemSearch',
            'displayUriRef',
        ]);
        const hasUriRefChanged = this.hasPropChanged(nextProps, ['generatedUriRef']);
        const sortValueKeys = ['itemSearch', 'sort', 'currentOption', 'urlLabel'];
        const hasSortValueChanged = this.hasPropChanged(nextProps, sortValueKeys);

        /**
         * We're expecting that this will run before any components get notified of a search
         * browse page change.
         */
        if (hasUriRefChanged) {
            const gt = getPublisherTagInstance();
            tracking.resetGtmTracking();

            // FINDING-13256 reset tracking fired in DBL when URL updates w/o hard refresh
            this.props.reinitTracking();

            if (gt) {
                gt.pubads().refresh();
            }
        }

        if (this.hasDisplayUriRefChanged) {
            this.setState(prevState => {
                return { visitCount: ++prevState.visitCount };
            });
        }

        if (hasSortValueChanged) {
            trackSortEvent(this.getProp(nextProps, sortValueKeys));
        }
    }

    trackNullResultsPage(appliedFilters) {
        const totalResults = this.props?.itemSearch?.totalResults;
        const isFuzzyResults = !!getFilterValueByUrlLabel(
            appliedFilters,
            FILTER_SEARCH_TYPE,
            FUZZY
        );

        if (!this.hasDisplayUriRefChanged && (totalResults === 0 || isFuzzyResults)) {
            trackNullResultsDisplayed();
        }
    }

    trackUserSearchHistory() {
        const itemSearch = this.props?.itemSearch;
        const userId = this.props?.user?.serviceId;
        const pageType = itemSearch?.pageType;
        const displayUriRef = itemSearch?.displayUriRef;
        const totalResults = itemSearch?.totalResults;

        const hasResults = totalResults > 0;
        const isSearch = pageType === SEARCH;

        if (isSearch && userId && hasResults) {
            updateSaveSearchMutation(this.props.relay.environment, {
                favoriteId: null,
                page: displayUriRef,
                type: 'SEARCH_HISTORY',
                userId,
            });
        }
    }

    handleTrackingFired() {
        const {
            appliedFilters,
            pageType,
            sort,
            seller,
            displayUriRef,
            isEligibleForRerank,
            controlEngagedItemIds,
            edges,
        } = this.props?.itemSearch || {};
        const sortValue = sort?.currentOption?.urlLabel;

        addPersonalizationTrackingData(appliedFilters);

        this.trackNullResultsPage(appliedFilters);

        this.trackUserSearchHistory();

        personalizedRerankTracking({ pageType, sortValue, isEligibleForRerank });

        if (
            pageType === AUCTION ||
            ((pageType === MORE_FROM_SELLER || pageType === DEALER) &&
                (!checkRewardedSeller(seller || null) ||
                    !checkTradeUserUnderTopLevelLoyaltyTiers(this.props?.user || null)))
        ) {
            trackSellerBrandingRemovalAbTestVariant();
        }

        bestSellersSellabilityTracking({
            displayUriRef: displayUriRef || '',
            pageType,
        });

        const isSearchOrBuyOrAuctionPageType =
            pageType === SEARCH || pageType === BUY || pageType === AUCTION;
        const isRecommendedSort = sortValue === SortMap.recommended;
        if (
            isUserEngagementBoostAbTestVariantOrControl() &&
            !isSearchOrBuyOrAuctionPageType &&
            isRecommendedSort
        ) {
            const resultItems = isUserEngagementBoostAbTestControl()
                ? controlEngagedItemIds
                : edges.map(edge => edge?.node?.item?.serviceId);
            userEngagementBoostTracking(resultItems);
        }
        itemPriceInSellabilityTracking({ pageType, sortValue });
        trackActiveAbTests(this.props.itemSearch);
    }

    getProp(obj, keys = []) {
        return keys.reduce((acc, key) => {
            return acc?.[key];
        }, obj);
    }

    hasPropChanged(newProps, propNames = []) {
        const oldProp = this.getProp(this.props, propNames);
        const newProp = this.getProp(newProps, propNames);
        return oldProp && oldProp !== newProp;
    }

    getGtmPageInfo() {
        const { itemSearch, pageNumber, refineMenuFiltersMap, searchCorrectionTerm, user } =
            this.props;

        const {
            pageType,
            totalResults,
            appliedFilters,
            searchCorrections,
            appliedPersonalizationList,
        } = itemSearch;
        const searchType = searchCorrections?.searchType;
        const isVerifiedTrade = user?.isVerifiedTrade;
        const isModule = itemSearch?.browseContentModule;
        const showCategoryTiles = !isModule;

        const additional = {
            ...getPageviewData({
                searchCorrectionTerm,
                pageType,
                appliedFilters,
                totalResults,
                pageNumber,
                optPageURL: itemSearch?.displayUriRef,
                sortValue: itemSearch?.sort?.currentOption?.urlLabel,
                isModule,
                refineMenuFiltersMap,
                topCategoryL1: itemSearch?.topCategoryL1,
                searchType,
            }),
            dimension143: appliedPersonalizationList?.length ? appliedPersonalizationList : null,
        };

        // track categoryTiles when they are visible and for non-trade users only
        if (showCategoryTiles && !isVerifiedTrade) {
            additional.categoryTilesVisible = itemSearch?.categoryTiles?.length ? 'y' : 'n';
        }

        return { additional };
    }

    render() {
        const { user, additional, viewer } = this.props;

        return (
            <SharedTrackingContainer
                visitCount={this.state.visitCount}
                onTrackingFired={this.handleTrackingFired}
                gtmPageInfo={this.getGtmPageInfo()}
                user={user}
                additional={additional}
                isEligibleForRerank={viewer?.isEligibleForRerank}
            />
        );
    }
}

const mapStateToProps = ({ filters }) => {
    const { generatedUriRef, page } = filters;
    return {
        pageNumber: page,
        generatedUriRef,
    };
};

SbSharedTrackingContainerComponent.propTypes = {
    relay: PropTypes.object.isRequired,
    viewer: PropTypes.object,
    user: PropTypes.object,
    itemSearch: PropTypes.object.isRequired,
    refineMenuFiltersMap: PropTypes.object.isRequired,
    pageNumber: PropTypes.number.isRequired,
    additional: PropTypes.object,
    // TODO INFRA: Should be required
    generatedUriRef: PropTypes.string,
    reinitTracking: PropTypes.func,
    searchCorrectionTerm: PropTypes.string,
};

SbSharedTrackingContainerComponent.defaultProps = {
    reinitTracking: () => {},
};

SbSharedTrackingContainerComponent.contextType = ServerVarsContext;

export const SbSharedTrackingContainer = createFragmentContainer(
    connect(mapStateToProps)(SbSharedTrackingContainerComponent),
    {
        viewer: graphql`
            fragment SbSharedTrackingContainer_viewer on Viewer {
                isEligibleForRerank(userId: $rerankUserId, guestId: $rerankGuestId)
                    @include(if: $isClient)
            }
        `,
        itemSearch: graphql`
            fragment SbSharedTrackingContainer_itemSearch on ItemSearchQueryConnection {
                topCategoryL1
                pageType
                totalResults
                displayUriRef
                appliedPersonalizationList
                browseContentModule(contentModuleId: $contentModuleId) {
                    __typename
                }
                appliedFilters {
                    name
                    values {
                        displayName
                        urlLabel
                        code
                    }
                }
                sort {
                    currentOption {
                        urlLabel
                    }
                }
                categoryTiles {
                    __typename
                }
                searchCorrections {
                    searchType
                }
                seller {
                    ...sellerBrandingHelpers_seller
                }
                controlEngagedItemIds
                edges {
                    node {
                        item {
                            serviceId
                        }
                    }
                }
                ...SbSharedTrackingFunctions_itemSearch
            }
        `,
        user: graphql`
            fragment SbSharedTrackingContainer_user on User {
                serviceId
                isVerifiedTrade
                ...SharedTrackingContainer_user
                ...sellerBrandingHelpers_user
            }
        `,
    }
);
